import React from "react"
import "../scss/index.scss"
import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import { Helmet } from "react-helmet"
import Footer from "../components/layout/footer"
import HeroInner from "../components/hero/hero-inner"

export default function ErrorPage () {
    return <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Water Restriction - Eco Wash Solutions | Anywhere Anytime We Come To You</title>
          <description>Auckland wide there are millions of vehicles on the roads every day. They are getting dirty with daily driven. It is very important to clean and maintain them because this is your investment. Every vehicle should clean once a week or every fortnightly or must be every four weeks time. Also upholstery and carpet need to clean</description>
          <link rel="canonical" href="https://ecocarwash.co.nz" />
        </Helmet>
  <Header />
  <Layout>
  {/* Hero Block */}
  <HeroInner  title="404 Not Found" description="Anywhere Anytime We Come to You" />
  <div className="container">
    <h3>Oops! The page you are looking for is not found.</h3>
  </div>
  </Layout>
  <Footer copyright="Copyright &copy; 2020 Eco Wash Solutions. All Rights Reserved" />
  </>
}